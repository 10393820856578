import React, { useEffect, useMemo, useState } from 'react';
import {
    MaterialReactTable,
    useMaterialReactTable,
    MRT_ExpandAllButton,
} from 'material-react-table';
import { Box, Stack, Button, TableFooter, TableRow, TableCell, Menu, MenuItem } from '@mui/material';
import { SiMicrosoftexcel } from "react-icons/si";
import { FaRegFilePdf } from "react-icons/fa";
import { mkConfig, generateCsv, download } from 'export-to-csv';
import { jsPDF } from 'jspdf'; //or use your library of choice here
import autoTable from 'jspdf-autotable';
import './css/GeneralTable.css';
import { MRT_Localization_EN } from 'material-react-table/locales/en';
import { MRT_Localization_ES } from 'material-react-table/locales/es';

const GeneralTable = ({ data, columns_table, actions, rowActions, groupFiles = false, exportFile = false,
    reportId, dropDown, startDate, endDate }) => {
    const language = JSON.parse(localStorage.getItem('language'));
    const currentLanguage = localStorage.getItem('currentLanguage');
    const [totalRow, setTotalRow] = useState({
        Coins: 0,
        Bills: 0,
        Credit: 0,
        QrIn: 0,
        QrOut: 0,
        Total: 0,
        Revenue: 0,
        BillsCoins: 0,
        TotalProductCost: 0
    });

    const [rowSelection, setRowSelection] = useState({});
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const languageCode = currentUser.data.userData.languageCode;
    const currency = currentUser.data.catalog.currency[0];
    const [anchorElCSV, setAnchorElCSV] = useState(null);
    const openCSV = Boolean(anchorElCSV);
    const [anchorElPDF, setAnchorElPDF] = useState(null);
    const openPDF = Boolean(anchorElPDF);
    const reportsWithTotals = [6, 7, 8, 9, 10, 11, 12];

// Updates totals when data or selection changes
    useEffect(() => {
        calculateTotal(data, "selection");
    }, [data, rowSelection, columns_table]);

    const handleClickExport = (event, fileType) => {
        if (fileType === "csv") {
            setAnchorElCSV(event.currentTarget);
        } else {
            setAnchorElPDF(event.currentTarget);
        }
    };

    const handleCloseExport = (fileType) => {
        if (fileType === "csv") {
            setAnchorElCSV(null);
        } else {
            setAnchorElPDF(null);
        }
    };

    const getColumnTotal = (rowData, columnToCalculate) => {
        const total = rowData.reduce((sum, row) => sum + parseFloat(row[columnToCalculate] || 0), 0);
        return total;
    };

    const calculateTotal = (rows, action) => {
        let newTotalRow = {
            Coins: 0,
            Bills: 0,
            Credit: 0,
            QrIn: 0,
            QrOut: 0,
            Total: 0,
            Revenue: 0,
            BillsCoins: 0,
            TotalProductCost: 0
        };
        if (action === "selection") {
            Object.keys(totalRow).forEach(column => {
                let total = 0;

                if (Object.keys(rowSelection).length > 0) {
                    Object.keys(rowSelection).forEach(key => {
                        const rowIndex = Number(key);
                        const row = data[rowIndex];
                        if (row && row[column] !== undefined) {
                            total += Number(row[column]);
                        }
                    });
                    if (column in newTotalRow) {
                        newTotalRow[column] = total;
                    }
                } else {
                    if (column in newTotalRow) {
                        newTotalRow[column] = getColumnTotal(data, column);
                    }
                }
            });
        } else {
            if (rows.length > 0) {
                const availableColumns = Object.keys(rows[0].original  || {});
                Object.keys(newTotalRow).forEach(column => {
                    if (!availableColumns.includes(column)) return; 
                    let total = 0;
                    rows.forEach(row => {
                        const value = row.original ? row.original[column] : row[column];
                        if (value !== undefined) {
                            total += Number(value);
                        }
                    });
                    newTotalRow[column] = total;
                });
            }
        }
        setTotalRow(newTotalRow);
    };

    const formatCurrency = (value, languageCode, currency) => {
        return new Intl.NumberFormat(languageCode, {
            style: 'currency',
            currency: currency.currency,
        }).format(value);
    };

// Calculates total for every column in table
    const columns = useMemo(() => {
        return columns_table.map((item) => {

            if (item.id in totalRow) {
                return {
                    ...item,
                    Footer: <div className='footer_total_amount'>{formatCurrency(totalRow[item.id], languageCode, currency)}</div>,
                };
            }
            return item;
        });
    }, [totalRow, columns_table]);

    const formatDate = (date) => {
        return `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')} ${String(date.getHours()).padStart(2, '0')}-${String(date.getMinutes()).padStart(2, '0')}`;
    };

    // const handleExportRowsPDF = (rows) => {
    //     const doc = new jsPDF();
    //     const tableData = rows.map((row) => Object.values(row.original));
    //     const tableHeaders = columns.map((c) => c.header);

    //     if (reportsWithTotals.includes(Number(reportId))) {
    //         const totalRow = getTotals(rows.map((row) => row.original));
    //         const separationRow = new Array(tableHeaders.length).fill('');
    //         separationRow[0] = `${language.Total} ${formatDate(startDate)} : ${formatDate(endDate)}`;
    //         tableData.push(separationRow);
    //         tableData.push(totalRow);
    //     }

    //     autoTable(doc, {
    //         head: [tableHeaders],
    //         body: tableData,
    //         didDrawCell: (data) => {
    //             if (data.row.index === tableData.length - 1) {
    //                 doc.setFont('helvetica', 'bold');
    //             } else {
    //                 doc.setFont('helvetica', 'normal');
    //             }
    //         },
    //     });
    //     const date = new Date();
    //     const formattedDate = formatDate(date);

    //     const reportName = getReportName();
    //     doc.save(`${reportName} ${formattedDate}.pdf`);
    // };

    const csvConfig = mkConfig({
        fieldSeparator: ',',
        decimalSeparator: '.',
        useKeysAsHeaders: true,
    });

// Calculates totals row for exported file
    const getTotals = (rowData) => {
        const parsedReportId = Number(reportId);
        const headers = columns_table.map(item => item.id);
        let columnsToCalculate = [];

        switch (parsedReportId) {
            case 6:
            case 12:
                columnsToCalculate = ['Coins', 'Bills', 'Credit', 'QrIn', 'QrOut', 'Total', 'Revenue'];
                break;
            case 7:
                columnsToCalculate = ['Credit', 'QrIn', 'QrOut', 'Revenue', 'BillsCoins'];
                break;
            case 8:
            case 9:
            case 10:
                columnsToCalculate = ['TotalProductCost'];
                break;
            case 11:
                columnsToCalculate = ['BillsCoins', 'Coins', 'Bills'];
                break;
            default:
                break;
        };

        const totals = columnsToCalculate.reduce((acc, column) => {
            acc[column] = rowData.reduce((sum, row) => sum + parseFloat(row[column] || 0), 0);
            return acc;
        }, {});

        const totalRow = headers.map((header) => {
            if (columnsToCalculate.includes(header)) {
                return totals[header] ? totals[header].toFixed(2) : '0.00';
            } else {
                return '';
            }
        });
        return totalRow;
    };

    const getReportName = () => {
        const report = dropDown.find(report => report.idCustomReports === Number(reportId));
        return report.name;
    };

    const handleExportRows = (rows) => {
        const rowData = rows.map((row) => row.original);
        const csv = generateCsv(csvConfig)(rowData);
        let finalCsv = '';
        if (reportsWithTotals.includes(Number(reportId))) {
            const totalRow = getTotals(rowData);
            finalCsv = `${csv}\n${language.Total} ${formatDate(startDate)} / ${formatDate(endDate)}${totalRow.join(',')}`;
        } else {
            finalCsv = `${csv}`;
        }

        const date = new Date();
        const formattedDate = formatDate(date);

        const blob = new Blob([finalCsv], { type: 'text/csv' });
        const reportName = getReportName();
        const fileName = `${reportName} ${formattedDate}.csv`;

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);
    };

    const handleExportData = () => {
        const csvData = generateCsv(csvConfig)(data);
        let finalCsv = '';
        if (reportsWithTotals.includes(Number(reportId))) {
            const totalRow = getTotals(data);
            finalCsv = `${csvData}\n${language.Total} ${formatDate(startDate)} / ${formatDate(endDate)}${totalRow.join(',')}`;
        } else {
            finalCsv = `${csvData}`;
        }

        const date = new Date();
        const formattedDate = formatDate(date);

        const blob = new Blob([finalCsv], { type: 'text/csv' });
        const reportName = getReportName();
        const fileName = `${reportName} ${formattedDate}.csv`;

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = fileName;
        document.body.appendChild(link);

        link.click();
        document.body.removeChild(link);
    };

    let shouldGroupColumns = groupFiles/*groupFiles && groupFiles.length > 0*/;

    const table = useMaterialReactTable({
        enableDensityToggle: false,
        enableColumnActions: actions,
        enableRowActions: actions,
        enableClickToCopy: true,
        columns,
        data,
        enableColumnOrdering: true,
        enableGlobalFilter: true,
        renderRowActions: rowActions,
        positionActionsColumn: 'last',
        enableStickyHeader: true,
        enableStickyFooter: true,
        defaultColumn: {
            maxSize: 100,
            minSize: 20,
            size: 50,
        },
        initialState: {
            density: 'compact',
            expanded: true
        },
        enableColumnResizing: true,
        columnResizeMode: 'onChange',
        layoutMode: 'semantic',
        muiTableHeadCellProps: {
            sx: {
                border: '1px solid #D7D7D7',
                fontWeight: 'bold',
                fontFamily: 'Open Sans bold',
                fontSize: '1.2rem',
                color: '#6A6A6A',
                '& .Mui-TableHeadCell-Content': {
                    justifyContent: 'center',
                },
                backgroundColor: 'white',
                height: '50px',
                alignContent: 'center',
                alignItems: 'center'
            },
            align: 'left',
            size: 'fit-content',

        },
        muiTableBodyCellProps: {
            sx: {
                border: '1px solid #D7D7D7',
                fontFamily: 'Open Sans Semibold',
                fontSize: '0.9rem',
            },
            align: 'center'
        },
        muiBottomToolbarProps: {
            sx: {
                backgroundColor: '#EBEBEB',
                boxShadow: '0',
                borderTop: '1px solid #D7D7D7',
                borderBottom: '1px solid #D7D7D7',
                borderColor: '#D7D7D7',
                marginTop: '35px'
            },

        },
        muiTopToolbarProps: {
            sx: {
                border: '0',
                boxShadow: '0',
                marginLeft: '0',
                backgroundColor: '#EBEBEB'
            },
            align: 'center'
        },
        muiTablePaperProps: {
            sx: {
                m: 'auto',
                maxWidth: '100%',
                backgroundColor: '#EBEBEB',
                borderRadius: '12px',
                border: '1px solid #D7D7D7',
                overflow: 'hidden',
            },
            elevation: 0,
        },
        muiPaginationProps: {
            labelRowsPerPage: 'x'
        },
        muiTableBodyProps: {
            sx: {
                '.Mui-ToolbarDropZone': {
                    backgroundColor: 'black'
                }
            }
        },
        muiPaginationProps: {
            sx: {
                backgroundColor: 'white'
            },
        },
        displayColumnDefOptions: {
            'mrt-row-actions': {
                header: language.Actions,
            },
            ...(shouldGroupColumns && {

                'mrt-row-expand': {
                    Header: () => (
                        <Stack direction="row" alignItems="center">
                            <MRT_ExpandAllButton table={table} />
                            <Box>Groups</Box>
                        </Stack>
                    ),
                    enableResizing: true,
                    muiTableBodyCellProps: () => ({
                        sx: () => ({
                            color: '#000',
                            fontWeight: 'bold'
                        }),
                    }),
                }
            })
        },
        ...(shouldGroupColumns && {
            enableGrouping: true,
            manualGrouping: false,
            groupedColumnMode: 'remove'
        }),
        ...(exportFile && {
            enableRowSelection: true,
        }),
        onRowSelectionChange: setRowSelection,
        state: {
            rowSelection,
        },
        localization: currentLanguage === 'ES' ? MRT_Localization_ES : MRT_Localization_EN
    });

// Updates totals in table when a filter is applied
    useEffect(() => {
        calculateTotal(table.getPrePaginationRowModel().flatRows, "filter");
    }, [table.getPrePaginationRowModel().rows]);

    return (
        <Box sx={{ margin: '0', maxWidth: '100%' }}>
            {exportFile && (
                <>
                    <Button
                        onClick={(e) => handleClickExport(e, "csv")}
                        startIcon={<SiMicrosoftexcel style={{ color: '#107C41' }} />}
                    />

                    {anchorElCSV && (
                        <Menu
                            anchorEl={anchorElCSV}
                            open={openCSV}
                            onClose={() => handleCloseExport("csv")}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <MenuItem
                                //export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
                                onClick={handleExportData}
                            >
                                {language.ExportAllData}
                            </MenuItem>
                            <MenuItem
                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                //export all rows, including from the next page, (still respects filtering and sorting)
                                onClick={() =>
                                    handleExportRows(table.getPrePaginationRowModel().rows)
                                }
                            >
                                {language.ExportAllRows}
                            </MenuItem>
                            <MenuItem
                                disabled={table.getRowModel().rows.length === 0}
                                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                onClick={() => handleExportRows(table.getRowModel().rows)}
                            >
                                {language.ExportPageRows}
                            </MenuItem>
                            <MenuItem
                                disabled={
                                    !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                }
                                //only export selected rows
                                onClick={() => handleExportRows(table.getSelectedRowModel().rows)}
                            >
                                {language.ExportSelectedRows}
                            </MenuItem>
                        </Menu>
                    )}

                    {/* Code below for PDF Exportation */}
                    {/* <Button
                        onClick={(e) => handleClickExport(e, "pdf")}
                        startIcon={<FaRegFilePdf style={{ color: '#B30B00' }} />}
                    />

                    {anchorElPDF && (
                        <Menu
                            anchorEl={anchorElPDF}
                            open={openPDF}
                            onClose={() => handleCloseExport("pdf")}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                        >
                            <MenuItem
                                disabled={table.getPrePaginationRowModel().rows.length === 0}
                                //export all rows, including from the next page, (still respects filtering and sorting)
                                onClick={() =>
                                    handleExportRowsPDF(table.getPrePaginationRowModel().rows)
                                }
                            >
                                {language.ExportAllRows}
                            </MenuItem>
                            <MenuItem
                                disabled={table.getRowModel().rows.length === 0}
                                //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                                onClick={() => handleExportRowsPDF(table.getRowModel().rows)}
                            >
                                {language.ExportPageRows}
                            </MenuItem>
                            <MenuItem
                                disabled={
                                    !table.getIsSomeRowsSelected() && !table.getIsAllRowsSelected()
                                }
                                //only export selected rows
                                onClick={() => handleExportRowsPDF(table.getSelectedRowModel().rows)}
                            >
                                {language.ExportSelectedRows}
                            </MenuItem>
                        </Menu>
                    )} */}
                </>
            )}

            {table && (
                <MaterialReactTable table={table} />
            )}
        </Box>

    );
}

export default GeneralTable;