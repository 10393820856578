import { fetchAuthSession  } from 'aws-amplify/auth';
import { get } from 'aws-amplify/api';
import 'bootstrap/dist/css/bootstrap.min.css';
import GetMethod from '../../Hooks/API/GetMethod';

const currentUser = JSON.parse(localStorage.getItem('currentUser'));

async function GetAllUsers(tenantsDB, RoleID) {
    const queryParameters = {
        tenantsDB,
        ...(RoleID ? { RoleID } : {})
    };
    const path = `/Users`;
    try {
        const { data, error } = await GetMethod(path,queryParameters);
        return { data, error };
    } catch (error) {
        return {error};
    }
}

export default GetAllUsers;