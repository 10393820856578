import React, { useEffect, useRef, useState } from "react";
import { IoIosSettings } from "react-icons/io";

import { Modal, Form, Button, OverlayTrigger, Popover } from "react-bootstrap";

import {
    DndContext,
} from "@dnd-kit/core";
import "./Dashboard.css";
import Droppable from "./Droppable/Droppable";
import generalDashBoard from "../../api/Dashboard/generalDashboard";
import getDetailInfo from "../../api/Dashboard/getDetailInfo";

import DashBoardTable from "./Table/DashBoardTable";
import DashboardTableTotalAlarm from "./Table/DashboardTableTotalAlarm";

import CurrentLanguageFile from "../../Hooks/language/CurrentLanguageFile";
import postDashboardFinancial from "../../api/Dashboard/postDashboardFinancial";
import useDragAndDrop from "../../Hooks/useDragAndDrop/useDragAndDrop";

import handleTimeChange from "../../Common/HandleTimeChange";
import getHour from "../../Common/GetHour";
import handleDateChange from "../../Common/HandleDateChange";
import getDate from "../../Common/GetDate";
import Footer from "../../Common/Footer";

function DashBoard() {
    const [isDraggable, setIsDraggable] = useState(false);
    const [isDraggableFinancial, setIsDraggableFinancial] = useState(false);

    const formatDate = (date, languageCode) => {
        return date.toLocaleDateString(languageCode, {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit',
            hour12: true
        });
    };

    const DateState = {
        CURRENTLY: 0,
        TODAY: 1,
        WEEK_DATE: 2,
        MONTH_DATE: 3,
        YEAR_TO_DATE: 4,
        CUSTOM_DATES: 5
    };

    // #region get localStorage
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const languageCode = currentUser.data.userData.languageCode;
    const language = JSON.parse(localStorage.getItem('language'));
    const currency = currentUser.data.catalog.currency[0];
    const generalAlarmSendingDelayInSeconds = currentUser.data.catalog.globalConfigTVM[0].generalAlarmSendingDelayInSeconds;
    //let language = [];


    const getCurrentMonthDateRange = () => {
        //local date
        const currentDate = new Date();
        const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        startOfMonth.setHours(0, 0, 0, 0);
        const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
        endOfMonth.setHours(23, 59, 59, 999);
        //UTC date
        const startOfMonthUTC = startOfMonth.toISOString();
        const endOfMonthUTC = endOfMonth.toISOString();


        return { startOfMonthUTC, endOfMonthUTC, startOfMonth, endOfMonth };
    };

    const { startOfMonth, endOfMonth } = getCurrentMonthDateRange();

    const fromDateRef = useRef(null);
    const toDateRef = useRef(null);

    const [headerTable, setHeaderTable] = useState([]);
    const [totalAlarm, setTotalAlarms] = useState([]);
    const [dateFinancial, SetDateFinancial] = useState(
        `${language.FromDate}: ${formatDate(startOfMonth, languageCode)} 
        ${language.ToDate}: ${formatDate(endOfMonth, languageCode)}`
    );
    const [data, setData] = useState([]);
    const [error, setError] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isRealTime, setIsRealTime] = useState(1);
    const [toDate, setToDate] = useState('');
    const [fromDate, setFromDate] = useState('');
    const [customDate, setCustomDate] = useState(false);
    const [dateState, setDateState] = useState(DateState.CURRENTLY);
    const [dateBtn, setDateBtn] = useState('');

    const initialItems1 = { dashboard1: [], dashboard2: [] };
    const initialItems2 = { dashboard3: [], dashboard4: [] };
    const { items: items1, sensors: sensors1, handleDragOver: handleDragOver1, handleDragEnd: handleDragEnd1, setItems: setItems1 } = useDragAndDrop(initialItems1);
    const { items: items2, sensors: sensors2, handleDragOver: handleDragOver2, handleDragEnd: handleDragEnd2, setItems: setItems2 } = useDragAndDrop(initialItems2);

    // #endregion

    useEffect(() => {
        const fetchData = async () => {
            try {
                const tenantsDB = currentTenant;
                /******************** date financial *******************************/
                const { startOfMonthUTC, endOfMonthUTC } = getCurrentMonthDateRange();
                /*******************************************************************/
                let financialResponse;
                if (dateState === 5) {
                    financialResponse = await postDashboardFinancial({
                        tenantsDB,
                        card: {
                            fromDate: (fromDate ? fromDate.toISOString() : startOfMonthUTC),
                            toDate: (toDate ? toDate.toISOString() : endOfMonthUTC)
                        },
                        
                    });
                } else {
                    financialResponse = await postDashboardFinancial({
                        tenantsDB,
                        card: {
                            fromDate: (fromDate ? fromDate : startOfMonthUTC),
                            toDate: (toDate ? toDate : endOfMonthUTC)
                        },
                        
                    });
                }
                const dashboardCard = financialResponse.cardFinancial[0];
                const dashboardFinancial = Array.from({ length: 2 }, () => []);
                const dashboardEntries = Object.entries(dashboardCard);
                let cardCount = 1;
                dashboardEntries.map(item => {
                    const dashboardIndex = Math.min(Math.floor(cardCount / 15), 1);
                    dashboardFinancial[dashboardIndex].push({
                        id: cardCount,
                        name: item[0],
                        total: item[1]
                    });
                    cardCount++;
                });

                const [dashboard3, dashboard4] = dashboardFinancial;

                setItems2({
                    dashboard3,
                    dashboard4
                })

                let response;
                if(dateState === 5) {
                    response = await generalDashBoard({ 
                        tenantsDB, 
                        isRealTime, 
                        toDate: toDate.toISOString(), 
                        fromDate: fromDate.toISOString() });
                } else {
                    response = await generalDashBoard({ tenantsDB, isRealTime, toDate, fromDate });
                }
                const dashboardCardAlarms = response.DashboardCardAlarms;
                const dashboardTotalAlarms = response.totalAlarms;
                const headerTotalAlarms = response.AllTvmDashBoardAlarms.header;
                setTotalAlarms(dashboardTotalAlarms);
                setHeaderTable(headerTotalAlarms);
                const dashboards = Array.from({ length: 2 }, () => []);

                let alarmsCount = 0;

                Object.keys(dashboardCardAlarms).forEach((key) => {
                    let alarms = dashboardCardAlarms[key];
                    alarms.forEach((alarm) => {
                        alarm.id = key;
                        const dashboardIndex = Math.min(Math.floor(alarmsCount / 15), 1);
                        dashboards[dashboardIndex].push(alarm);
                        alarmsCount++;
                    });
                });


                const [dashboard1, dashboard2] = dashboards;
                setItems1({
                    dashboard1,
                    dashboard2
                });
            } catch (err) {
                console.log(err);
            }
        };

        setLanguage();

        fetchData();
        const intervalId = setInterval(() => {
            if (isRealTime) {
                fetchData();
            }
        }, (generalAlarmSendingDelayInSeconds ? generalAlarmSendingDelayInSeconds : 300) * 1000);
        return () => {
            clearInterval(intervalId);
        };
    }, [dateState]);

    const handleCardDetails = async (idDevice, typesOfDetails) => {
        try {
            const tenantsDB = currentTenant;
            const id = idDevice;
            const { data, error } = await getDetailInfo({ id, isRealTime, toDate, fromDate, tenantsDB, typesOfDetails })
            if (data) {
                setData(data);
                setShowModal(true);
            } else if (error) {
                setError(error);
                setShowModal(false);
            }
        } catch (err) {
            setError(err);
            console.log(err);
            setShowModal(false);
        }
    }

    const handleModalClose = () => {
        setShowModal(false);
    };

    const handleCurrently = () => {
        setFromDate('');
        setToDate('');
        setDateBtn('');
        setIsRealTime(1);
        setCustomDate(false);
        setDateState(DateState.CURRENTLY);
    }

    const handleToday = async () => {
        setCustomDate(false);
        const currentDate = new Date();
        //local date
        const startOfDay = new Date(currentDate);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(currentDate);
        endOfDay.setHours(23, 59, 59, 999);
        //UTC date
        const startOfDayUTC = startOfDay.toISOString();
        const endOfDayUTC = endOfDay.toISOString();
        setFromDate(startOfDayUTC);
        setToDate(endOfDayUTC);
        setIsRealTime(0);
        setDateState(DateState.TODAY);
        SetDateFinancial(`
            ${language.FromDate}: ${formatDate(startOfDay, languageCode)} 
            ${language.ToDate}: ${formatDate(endOfDay, languageCode)}`
        );
        setDateBtn(`
            ${language.FromDate}: ${formatDate(startOfDay, languageCode)} 
            ${language.ToDate}: ${formatDate(endOfDay, languageCode)}`
        );
    }

    const handleWeekDate = async () => {
        setCustomDate(false);
        const currentDate = new Date();
        //local date
        const firstDayOfWeek = new Date(currentDate);
        firstDayOfWeek.setDate(currentDate.getDate() - currentDate.getDay());
        firstDayOfWeek.setHours(0, 0, 0, 0);
        const lastDayOfWeek = new Date(firstDayOfWeek);
        lastDayOfWeek.setDate(firstDayOfWeek.getDate() + 6);
        lastDayOfWeek.setHours(23, 59, 59, 999);
        //UTC date
        const startOfWeekUTC = firstDayOfWeek.toISOString();
        const endOfWeekUTC = lastDayOfWeek.toISOString();

        setFromDate(startOfWeekUTC);
        setToDate(endOfWeekUTC);
        setIsRealTime(0);
        setDateState(DateState.WEEK_DATE);
        SetDateFinancial(`
            ${language.FromDate}: ${formatDate(firstDayOfWeek, languageCode)} 
            ${language.ToDate}: ${formatDate(lastDayOfWeek, languageCode)}`
        );
        setDateBtn(`
            ${language.FromDate}: ${formatDate(firstDayOfWeek, languageCode)} 
            ${language.ToDate}: ${formatDate(lastDayOfWeek, languageCode)}`
        );
    }

    const handleMonthDate = async () => {
        setCustomDate(false);
        const { startOfMonthUTC, endOfMonthUTC, startOfMonth, endOfMonth } = getCurrentMonthDateRange();
        setFromDate(startOfMonthUTC);
        setToDate(endOfMonthUTC);
        setDateState(DateState.MONTH_DATE);
        SetDateFinancial(`
            ${language.FromDate}: ${formatDate(startOfMonth, languageCode)} 
            ${language.ToDate}: ${formatDate(endOfMonth, languageCode)}`
        );
        setDateBtn(`
            ${language.FromDate}: ${formatDate(startOfMonth, languageCode)} 
            ${language.ToDate}: ${formatDate(endOfMonth, languageCode)}`
        );
    }

    const handleYearToDate = async () => {
        setCustomDate(false);
        const currentDate = new Date();
        //Local date
        const firstDayOfYear = new Date(currentDate.getFullYear(), 0, 1);
        firstDayOfYear.setHours(0, 0, 0, 0);
        const lastDayOfYear = currentDate;
        lastDayOfYear.setHours(23, 59, 59, 999);
        //UTC date
        const startOfWeekUTC = firstDayOfYear.toISOString();
        const endOfWeekUTC = lastDayOfYear.toISOString();

        setFromDate(startOfWeekUTC);
        setToDate(endOfWeekUTC);
        setIsRealTime(0);
        setDateState(DateState.YEAR_TO_DATE);
        SetDateFinancial(`
            ${language.FromDate}: ${formatDate(firstDayOfYear, languageCode)} 
            ${language.ToDate}: ${formatDate(lastDayOfYear, languageCode)}`
        );
        setDateBtn(`
            ${language.FromDate}: ${formatDate(firstDayOfYear, languageCode)} 
            ${language.ToDate}: ${formatDate(lastDayOfYear, languageCode)}`
        );
    }

    const handleCustomDates = () => {
        setToDate(() => {
            const initialDate = new Date();
            initialDate.setHours(23, 59, 59, 999);
            return initialDate;
        });
        setFromDate(() => {
            const initialDate = new Date();
            initialDate.setHours(0, 0, 0, 0);
            return initialDate;
        });
        setCustomDate(true);
        setIsRealTime(0);
        setDateBtn('');
    }

    const handleFromDateChange = (event) => {
        setFromDate(event.target.value);
    };

    const handleToDateChange = (event) => {
        setToDate(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();

        const selectedFromDate = fromDateRef.current.value;
        const selectedToDate = toDateRef.current.value;
        //select date
        const [yearFrom, monthFrom, dayFrom] = selectedFromDate.split('-');
        const [hoursFrom, minutesFrom, secondsFrom] = getHour(new Date(fromDate)).split(':');
        const [yearTo, monthTo, dayTo] = selectedToDate.split('-');
        const [hoursTo, minutesTo, secondsTo] = getHour(new Date(toDate)).split(':');
        const customFromDate = new Date(yearFrom, monthFrom - 1, dayFrom, hoursFrom, minutesFrom, secondsFrom, 0);
        const customToDate = new Date(yearTo, monthTo - 1, dayTo, hoursTo, minutesTo, secondsTo, 999);

        setIsRealTime(0);
        setDateState(DateState.CUSTOM_DATES);
        SetDateFinancial(`
            ${language.FromDate}: ${formatDate(customFromDate, languageCode)}
            ${language.ToDate}: ${formatDate(customToDate, languageCode)}`
        );
        setDateBtn(`
            ${language.FromDate}: ${formatDate(customFromDate, languageCode)}
            ${language.ToDate}: ${formatDate(customToDate, languageCode)}`
        );
    };

    const setLanguage = async () => {
        await CurrentLanguageFile(currentUser);
    };

    return (
        <>
            <section className="main-container-app" style={{minHeight: 'auto'}}>
                <div className="main-box">
                    <h4 className='dashboard-subtitle'>TVM</h4>
                    {totalAlarm.length > 0 && headerTable.length > 0 && (
                        <DashboardTableTotalAlarm device_data={totalAlarm} header={headerTable} language={language} />
                    )}
                </div>
            </section>
            <section className="main-container-app" style={{ paddingTop: 0, minHeight: 'auto' }}>
                <div className="dashboard-container">
                    <div className="dashboard-btn-container">
                        <button onClick={handleCurrently} className="dashboard-date-btn">{language.Currently}</button>
                        <button onClick={handleToday} className="dashboard-date-btn">{language.Today}</button>
                        <button onClick={handleWeekDate} className="dashboard-date-btn">{language.WeekDate}</button>
                        <button onClick={handleMonthDate} className="dashboard-date-btn">{language.MonthToDate}</button>
                        <button onClick={handleYearToDate} className="dashboard-date-btn">{language.YearToDate}</button>
                        <button onClick={handleCustomDates} className="dashboard-date-btn">{language.CustomDates}</button>
                    </div>
                    {
                        (customDate) && (<div className="dashboard-box-date">
                            <Form>
                                <Form.Group controlId="fromDate">
                                    <Form.Label>{language.FromDate}</Form.Label>
                                    <Form.Control
                                        type="date"
                                        ref={fromDateRef}
                                        onChange={event => handleDateChange(event, fromDate, setFromDate)}
                                        value={fromDate ? getDate(fromDate) : ''}
                                    />
                                    <Form.Control
                                        type="time"
                                        onChange={event => handleTimeChange(event, fromDate, setFromDate)}
                                        value={fromDate ? getHour(new Date(fromDate)) : ''}
                                    />
                                </Form.Group>
                                <Form.Group controlId="toDate">
                                    <Form.Label>{language.ToDate}</Form.Label>
                                    <Form.Control
                                        type="date"
                                        ref={toDateRef}
                                        onChange={event => handleDateChange(event, toDate, setToDate)}
                                        value={toDate ? getDate(toDate) : ''}
                                    />
                                    <Form.Control
                                        type="time"
                                        onChange={event => handleTimeChange(event, toDate, setToDate)}
                                        value={toDate ? getHour(new Date(toDate)) : ''}
                                    />
                                </Form.Group>
                                <Button className="Submit_btn" onClick={handleSubmit} variant="primary">{language.Submit}</Button>
                            </Form>
                        </div>)
                    }
                </div>
                {dateBtn && (<p className="data_label">{dateBtn}</p>)}
                <h4 className='module__title'>{language.GeneralDashboardT}</h4>
                <DndContext
                    sensors={sensors1}
                    onDragEnd={handleDragEnd1}
                    onDragOver={handleDragOver1}
                >

                    <div className="main-box" >
                        <div className="dashboard-settings">
                            {
                                (items1.length > 0) && (items1.dashboard1.length + items1.dashboard2.length) > 15 &&
                                (<IoIosSettings size={20} style={{ fill: '#727BBE' }} />)
                            }
                        </div>
                        <h4 className='dashboard-subtitle'>{language.TVMAlarms}</h4>
                        <div className={`cards-container ${isDraggable ? 'show-droppable' : ''}`}>
                            <div className="main-droppable">
                                <Droppable
                                    id="dashboard1"
                                    items={items1.dashboard1}
                                    cardType={isRealTime ? "Alerts" : "InformativeAlerts"}
                                    language={language}
                                    handleCardDetails={handleCardDetails}
                                    key="dashboard1"
                                    currentDashboard={"GeneralDashboard"}
                                    isDraggable={isDraggable}
                                    setIsDraggable={setIsDraggable}
                                />
                            </div>
                            {
                                isDraggable && (
                                    <div className="droppable-wrapper">
                                        <Droppable
                                            id="dashboard2"
                                            items={items1.dashboard2}
                                            cardType={isRealTime ? "Alerts" : "InformativeAlerts"}
                                            language={language}
                                            handleCardDetails={handleCardDetails}
                                            key="dashboard2"
                                            currentDashboard={"GeneralDashboard"}
                                            isDraggable={isDraggable}
                                            setIsDraggable={setIsDraggable}
                                            isMoreCards={true}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </DndContext>

                <DndContext
                    sensors={sensors2}
                    onDragEnd={handleDragEnd2}
                    onDragOver={handleDragOver2}
                >
                    <div className="main-box">
                        <div className="dashboard-settings">
                            {(Object.keys(items2).length > 0) && ((items2.dashboard3.length + items2.dashboard4.length) > 15) && (
                                <IoIosSettings size={20} style={{ fill: '#727BBE' }} />
                            )}
                        </div>
                        <h4 className="dashboard-subtitle">{language.Financial} TVM</h4>
                        <p className="date">{dateFinancial}</p>
                        <div className={`cards-container ${isDraggableFinancial ? 'show-droppable' : ''}`}>
                            <div className="main-droppable">
                                <Droppable
                                    id="dashboard3"
                                    items={items2.dashboard3}
                                    cardType={"Financial"}
                                    language={language}
                                    handleCardDetails={handleCardDetails}
                                    key="dashboard3"
                                    currentDashboard={"GeneralDashboard"}
                                    currency={currency}
                                    isDraggable={isDraggableFinancial}
                                    setIsDraggable={setIsDraggableFinancial}
                                />
                            </div>
                            {
                                isDraggableFinancial && (
                                    <div className="droppable-wrapper">
                                        <Droppable
                                            id="dashboard4"
                                            items={items2.dashboard4}
                                            cardType={"Financial"}
                                            language={language}
                                            handleCardDetails={handleCardDetails}
                                            key="dashboard4"
                                            currentDashboard={"GeneralDashboard"}
                                            currency={currency}
                                            isDraggable={isDraggableFinancial}
                                            setIsDraggable={setIsDraggableFinancial}
                                            isMoreCards={true}
                                        />
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </DndContext>
            </section>

            <Footer/>

            <Modal size="xl" backdrop={false} animation={true} keyboard={true} show={showModal} onHide={handleModalClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{language.Alarms}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {data.length > 0 && <DashBoardTable device_data={data} language={language} />}
                </Modal.Body>
            </Modal>
        </>
    );
}

export default DashBoard;