import React, { useState, useEffect, useMemo } from 'react';

import {
    MaterialReactTable,
    useMaterialReactTable,
} from 'material-react-table';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPencil } from '@fortawesome/free-solid-svg-icons';
import { faTrashCan } from '@fortawesome/free-regular-svg-icons';

import { Box } from '@mui/material';

import getPermission from '../../auth/GetPermission.js';

import AddButton from '../../Common/AddButton.js'

import './css/CountriesPage.css';

import Spinner from 'react-bootstrap/Spinner';
import getStations from '../../api/Location/getStations.js';
import StationsNewModal from './StationsNewModal.js';
import StationsEditModal from './StationsEditModal.js';
import deleteStation from '../../api/Location/deleteStation.js';
import GeneralTable from '../../Hooks/Table/GeneralTable.js';
import DeleteModal from '../../Common/DeleteModal';
import ErrorCases from '../../Common/ErrorCases.js';
import Footer from '../../Common/Footer.js';

function Stations() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [showNewModal, setShowNewModal] = useState(false);
    const [showEditModal, setShowEditModal] = useState(false);
    const [stationId, setStationId] = useState(0);
    const [station, setStation] = useState([]);
    const language = JSON.parse(localStorage.getItem('language'));
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deletingStation, setDeletingStation] = useState([]);
    const [deleteError, setDeleteError] = useState('');

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getData();
                setData(response);
                setIsLoading(false);
            } catch (error) {
                setError(error.message);
            }
        }
        fetchData();
    }, []);

    async function getData() {
        const { data, error } = await getStations(currentUser.data.currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    }

    const handleEditClick = (id) => {
        setStationId(id);
        const stationToEdit = data.find((station) => station.idStation === id);
        setStation({
            nameStation: stationToEdit.stationName, consecutiveNumber: stationToEdit.consecutiveNumber,
            isActive: stationToEdit.isActive, screen: stationToEdit.screen, position: stationToEdit.position, zoneId:
                stationToEdit.zoneId, tenantsDB: currentUser.data.currentTenant
        });
        setShowEditModal(true);
    };

    async function handleDeleteClick(id) {
        try {
            setIsLoading(true);
            const stationToDelete = { id: id, tenantsDB: currentUser.data.currentTenant };
            const response = await deleteStation(stationToDelete);
            if (response.error) {
                const error = ErrorCases(response.error);
                setDeleteError(error);
                return;
            }
            const newData = await getData();
            setData(newData);
            setShowDeleteModal(false);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }

    }

    const deleteConfirmation = (station) => {
        setDeleteError('');
        setDeletingStation(station);
        setShowDeleteModal(true);
    };

    const columns = useMemo(
        () => [
            {
                header: language.Station,
                accessorKey: 'stationName',
            },
            {
                header: language.StationNumber,
                accessorKey: 'consecutiveNumber',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {
                header: language.Position,
                accessorKey: 'position',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {
                header: language.ZoneNumber,
                accessorKey: 'zoneId',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
            },
            {
                header: language.Active,
                accessorKey: 'isActive',
                Cell: ({ renderedCellValue, row }) => (
                    <Box>
                        <img src={renderedCellValue === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'} className='table__checkbox' />
                    </Box>
                ),
            }
        ],
        [],
    );

    const rowActions = ({ row }) => (
        <Box sx={{ display: 'flex', gap: '1rem' }}>
            <button
                className="btn"
                onClick={() => handleEditClick(row.original.idStation)}
                disabled={!getPermission(currentUser, "LocationStation", "updateAllow")}>
                <img src='./img/icons/pages/EditUser.svg' className='table__icon' />
            </button>
            <button
                className="btn"
                onClick={() => deleteConfirmation(row.original)}
                disabled={(!getPermission(currentUser, "LocationStation", "deleteAllow"))}>
                <FontAwesomeIcon icon={faTrashCan} className='table__icon svg-icon' />
            </button>
        </Box>
    );

    return (
        <div className='main-container-app'>
            <h1 className='module__title'>{language.Stations}</h1>

            {
                getPermission(currentUser, "LocationStation", "readAllow") &&
                <>
                    <GeneralTable data={data} columns_table={columns} actions={true} rowActions={rowActions} />
                    <StationsNewModal
                        show={showNewModal}
                        onHide={() => setShowNewModal(false)}
                        getData={getData}
                        setData={setData}
                    />
                    <StationsEditModal
                        show={showEditModal}
                        onHide={() => setShowEditModal(false)}
                        editingStation={station}
                        editingStationId={stationId}
                        getData={getData}
                        setData={setData}
                    />
                </>
            }

            {
                showDeleteModal &&
                <DeleteModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    message={language.DeleteStationMessage + deletingStation.stationName}
                    handleDelete={() => handleDeleteClick(deletingStation.idStation)}
                    error={deleteError}
                />
            }

            {
                getPermission(currentUser, "LocationStation", "writeAllow") &&
                <div className='addbutton__container' onClick={() => setShowNewModal(true)}><AddButton /></div>
            }

            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
            <Footer/>
        </div>

    );
}

export default Stations;