import React, { useState } from "react";
import './css/RoleCard.css'
import { ListGroup, Modal } from "react-bootstrap";
import GetAllUsers from "../../api/EMSuser/getAllUsers";

function RoleCard(props) {
    const language = JSON.parse(localStorage.getItem('language'));
    const [showModal, setShowModal] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [roleMembers, setRoleMembers] = useState([]);

    const handleMembersModal = async () => {
        try {
            props.setIsLoading(true);
            const response = await GetAllUsers(currentTenant, props.id);
            setRoleMembers(response.data);
            setShowModal(true);
        } catch (error) {
            console.error(error);
        } finally {
            props.setIsLoading(false);
        }
    };

    return (
        <>
            <div className="role-card">
                <div className="role-card__header">
                    {props.isActive === 1 ?
                        <img src='./img/icons/pages/ActiveRole.svg' className='role-card__header-icon' />
                        : <img src='./img/icons/pages/InactiveRole.svg' className='role-card__header-icon' />}

                    <div className="role-card__header-title">{props.title}</div>
                    <img src='./img/icons/pages/EditUser-01.png' className='role-card__edit-icon' onClick={() => props.handleEditClick(props.id)} />
                </div>
                <div className="role-card__description">{props.description}</div>
                <div className="role-card__footer">
                    <div className="role-card__members"
                        onClick={handleMembersModal}>
                        <img src='./img/icons/pages/User Management.svg' className='role-card__footer-icon' /> {props.members} {language.Members}</div>
                </div>
            </div>
            <Modal show={showModal}
                onHide={() => setShowModal(false)}
                centered
                className="members-modal"
            >
                <Modal.Header closeButton>
                    <Modal.Title style={{ textAlign: 'center', width: '100%' }}>
                        {props.title}
                    </Modal.Title>
                </Modal.Header>

                <Modal.Body>
                    {roleMembers.length > 0 ?
                        <ListGroup >
                            {roleMembers.map((member, index) => {
                                return (
                                    <ListGroup.Item className='card__item'>
                                        {index + 1}. {member.name}</ListGroup.Item>
                                )
                            })}
                        </ListGroup>
                        :
                        <div className="members-modal-message">{language.NoUsersMessage}</div>
                    }
                </Modal.Body>
            </Modal>
        </>
    );
}

export default RoleCard;