import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import getAllRoles from '../../api/Roles/getAllRoles';
import createRole from '../../api/Roles/createRole';
import editRole from '../../api/Roles/editRole';
import RoleCard from './RoleCard';
import Paginator from '../../Common/Paginator';
import getRole from '../../api/Roles/getRole';
import GetUser from '../../api/EMSuser/getUser';
import './css/RolesPage.css';
import AddButton from '../../Common/AddButton';

import { FaSearch } from "react-icons/fa";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ModalHeader, Spinner } from "react-bootstrap";
import Footer from '../../Common/Footer';

function RolePage() {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [editingRoleId, setEditingRoleId] = useState(null);
    const [editedRoleData, setEditedRoleData] = useState({ isActive: false });
    const [isEditing, setIsEditing] = useState(false);
    const [roleNameFilter, setRoleNameFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [elementsPerPage] = useState(5);
    const [viewRoles, setViewRoles] = useState(true);
    const [viewPermissions, setViewPermissions] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [roleName, setRoleName] = useState("");
    const [roleDescription, setRoleDescription] = useState("");
    const [isRoleActive, setIsRoleActive] = useState(true);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const [showAlert, setShowAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [selectedRole, setSelectedRole] = useState(undefined);
    const [rolePermissions, setRolePermissions] = useState([]);
    const [totalItems, setTotalItems] = useState(0);
    let total = 0;
    const [showMainAlert, setShowMainAlert] = useState(false);
    const [alertVariant, setAlertVariant] = useState("");
    const language = JSON.parse(localStorage.getItem('language'));
    const [isLoading, setIsLoading] = useState(false);
    const [viewConfirmModal, setViewConfirmModal] = useState(false);
    const [confirmingAction, setConfirmingAction] = useState([]);

    useEffect(() => {
        async function fetchData() {
            try {

                const response = await getData();
                setTotalItems(data.length);
                setData(response.roles);
            } catch (error) {
                setError(error.message);
            }
        }

        setIsLoading(true);
        fetchData();
        setIsLoading(false);
    }, [currentPage]);

    async function getData() {
        const page = currentPage.toString() - 1;
        const elements = elementsPerPage.toString();
        const isActive = '1';
        const { data, error } = await getAllRoles(currentTenant);
        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    }

    const handleEditClick = (roleId) => {
        const roleToEdit = data.find((role) => role.id === roleId);
        setEditingRoleId(roleId);
        setEditedRoleData(roleToEdit);
        setIsEditing(true);
        setShowModal(true);
        setRoleName(roleToEdit.name);
        roleToEdit.isActive ? setIsRoleActive(true) : setIsRoleActive(false);
        setRoleDescription(roleToEdit.description);
    };

    const handleSaveEditClick = async () => {
        setIsLoading(true);
        try {
            const response = await getRole(editingRoleId);
            const permissions = response.data.permissions;
            const roleData = {
                name: roleName,
                isActive: isRoleActive ? 1 : 0,
                description: roleDescription,
                roleAssignment: permissions
            }
            await editRole(roleData, editingRoleId);
            const data = await getData();
            setData(data.roles);
            handleCancelClick();
            setAlertMessage(language.RoleSaved);
            setAlertVariant("primary");
            setShowMainAlert(true);
        } catch (error) {
            setAlertMessage(language.ErrorOcurred);
            setAlertVariant("danger");
            setShowMainAlert(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCancelClick = () => {
        setIsEditing(false);
        setEditingRoleId(null);
        setEditedRoleData({});
        setRoleName("");
        setRoleDescription("");
        setShowModal(false);
    };

    const handleShowRoles = () => {
        setViewPermissions(false);
        setViewRoles(true);
        setRolePermissions([]);
    };

    const handleShowPermissions = () => {
        setViewRoles(false);
        setViewPermissions(true);
    };

    const handleInputChange = (event) => {
        const { name, value, type, checked } = event.target;
        const newValue = type === 'checkbox' ? (checked == true ? 1 : 0) : value;
        setEditedRoleData({ ...editedRoleData, [name]: newValue });
    };

    const [selectedOption, setSelectedOption] = useState('');

    const handleSelectChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const toggleShowModal = () => setShowModal(!showModal);

    const handleCreateRole = async () => {
        setIsLoading(true);
        if (roleName !== "" && roleDescription !== "") {
            try {
                const roleData = {
                    name: roleName,
                    isActive: isRoleActive ? 1 : 0,
                    description: roleDescription,
                    tenantsDB: currentTenant,
                    roleAssignment: defaultRoleAssignment
                }
                await createRole(roleData);
                const response = await getData();
                setData(response.roles);
                setAlertMessage(language.RoleCreated);
                setAlertVariant("primary");
                setShowMainAlert(true);
                handleCancelClick();
            } catch (error) {
                setAlertMessage(language.ErrorOcurred);
                setShowAlert(true);
            }
        } else {
            setAlertMessage(language.EnterRoleName);
            setShowAlert(true);
        }
        setIsLoading(false);
    };

    const handleCheckboxChange = () => {
        setIsRoleActive(!isRoleActive);
    };

    const defaultRoleAssignment = currentUser.data.permission.map((permission) => {
        return {
            idModule: permission.idModule,
            readAllow: 0,
            writeAllow: 0,
            updateAllow: 0,
            deleteAllow: 0
        };
    });

    const handleSelectedRole = async (event) => {
        setIsLoading(true);
        try {
            const response = await getRole(event.target.value);
            const permissions = response.data.permissions;
            const roleToEdit = response.data.role[0];
            setRolePermissions(permissions);
            setEditedRoleData(roleToEdit);
            setEditingRoleId(roleToEdit.id);
        } catch (error) {
            //console.log(error);
        }
        setIsLoading(false);
    };

    const handleChangePermission = (index, action, module) => {
        if (module === 'UMRoles') {
            let actionToConfirm = '';
            switch (action) {
                case 'readAllow':
                    actionToConfirm = language.Read;
                    break;
                case 'writeAllow':
                    actionToConfirm = language.Create;
                    break;
                case 'updateAllow':
                    actionToConfirm = language.Update;
                    break;
                case 'deleteAllow':
                    actionToConfirm = language.Delete;
                    break;
                default:
                    actionToConfirm = language.Permissions;
                    break;
            }
            setConfirmingAction({index, action, name: actionToConfirm});
            setViewConfirmModal(true);
        }
        else {
            changeConfirmedPermission(index, action);
        }
    };

    const changeConfirmedPermission = (index, action) => {
        let newPermission = rolePermissions;
        newPermission[index][action] === 1 ? newPermission[index][action] = 0 : newPermission[index][action] = 1;
        if (newPermission[index].readAllow === 0) {
            newPermission[index].writeAllow = 0;
            newPermission[index].updateAllow = 0;
            newPermission[index].deleteAllow = 0;
        }
        setRolePermissions([...newPermission]);
    };

    const confirmPermissionChange = (index, action) => {
        changeConfirmedPermission(index, action);
        setViewConfirmModal(false);
    };

    const handleSavePermissions = async () => {
        setIsLoading(true);
        try {
            const roleData = {
                name: editedRoleData.name,
                isActive: editedRoleData.isActive,
                description: editedRoleData.description,
                roleAssignment: rolePermissions
            }
            await editRole(roleData, editingRoleId);
            if (editingRoleId === currentUser.data.userData.roleId) {
                const currentUser = await GetUser();
                localStorage.setItem('currentUser', JSON.stringify(currentUser));
            }
            const data = await getData();
            setData(data.roles);
            setAlertMessage(language.PermissionsSaved);
            setAlertVariant("primary");
            setShowMainAlert(true);
        } catch (error) {
            setAlertMessage(language.ErrorOcurred);
            setShowAlert(true);
        }
        setIsLoading(false);
    }

    const filteredRoles = () => {
        if (roleNameFilter.length === 0) {
            return data.slice(currentPage, currentPage + 6);
        }

        const filtered = data.filter(role => role.name.toLowerCase().includes(roleNameFilter.toLowerCase()));
        total = filtered.length;
        return filtered.slice(currentPage, currentPage + 6);
    }

    const nextPage = () => {
        if (roleNameFilter.length > 0 && (currentPage + 6) < data.filter(role => role.name.includes(roleNameFilter)).length) {
            setCurrentPage(currentPage + 6);
        } else if (roleNameFilter.length === 0 && (currentPage + 6) < data.length) {
            setCurrentPage(currentPage + 6);
        }
    }

    const previousPage = () => {
        if (currentPage > 0) {
            currentPage - 6 < 0 ? setCurrentPage(0) : setCurrentPage(currentPage - 6);
        }
    }

    const firstPage = () => {
        setCurrentPage(0);
    }

    const lastPage = () => {
        setCurrentPage(filteredRoles().length + 1);
    }

    const handleFilter = (evt) => {
        setCurrentPage(0);
        setRoleNameFilter(evt.target.value);
    }

    return (
        <>
            <div className='main-container-app'>
                {showMainAlert &&
                    <Alert variant={alertVariant}
                        onClose={() => setShowMainAlert(false)}
                        dismissible
                        className='alert'>
                        {alertMessage}
                    </Alert>
                }
                <h1 className='module__title'>{language.RolesManagement}</h1>
                <div className='main-box__header'>
                    <div className='main-box__submenu'>
                        <div className={viewRoles ? 'main-box__submenu-element active' : 'main-box__submenu-element inactive'} onClick={handleShowRoles}>{language.Roles}</div>
                        <div className={viewPermissions ? 'main-box__submenu-element active' : 'main-box__submenu-element inactive'} onClick={handleShowPermissions}>{language.Permissions}</div>
                    </div>
                    {viewRoles &&
                        <div className='search-input__box'>
                            <img src='./img/icons/pages/Seeker.svg' className='table__icon' />
                            <input placeholder={language.Search}
                                value={roleNameFilter}
                                onChange={handleFilter}
                                className='search-input'
                            />
                        </div>
                    }

                </div>
                <div >

                    {viewRoles &&
                        <>
                            <div className='main-box__role-cards'>
                                {filteredRoles().map(item => {
                                    return <div key={item.id} ><RoleCard key={item.id}
                                        title={item.name}
                                        isActive={item.isActive}
                                        description={item.description}
                                        members={item.members} 
                                        handleEditClick={handleEditClick}
                                        id={item.id}
                                        setIsLoading={setIsLoading}
                                        /></div>;
                                })}
                            </div>
                            <Paginator nextPage={nextPage}
                                previousPage={previousPage}
                                firstItem={currentPage + 1}
                                lastItem={filteredRoles().length < (currentPage + 6) ?
                                    currentPage + filteredRoles().length :
                                    currentPage + 6
                                }
                                totalItems={roleNameFilter.length === 0 ? data.length : total}
                                firstPage={firstPage}
                                lastPage={lastPage}
                            />
                        </>
                    }

                    {viewPermissions &&
                        <div>
                            <div className="main-box">
                                <select className="module__input-search" value={selectedRole} onChange={handleSelectedRole}
                                    style={{ width: "max-content" }}>
                                    <option className='table-row__text' value="">{language.SelecARole}</option>
                                    {
                                        data.map(item => {
                                            return <option key={item.id} value={item.id} className='table-row__text'>
                                                {item.name}
                                            </option>
                                        })
                                    }
                                </select>

                                {rolePermissions.length > 0 &&
                                    <table className='permissions-table'>
                                        <thead>
                                            <tr>
                                                <th className='table-header'>{language.Module}</th>
                                                <th className='table-header'>{language.Read}</th>
                                                <th className='table-header'>{language.Create}</th>
                                                <th className='table-header'>{language.Update}</th>
                                                <th className='table-header'>{language.Delete}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rolePermissions.map((permission, index) => {
                                                return <tr key={permission.idModule}>
                                                    <td className='table-row__text'>{language[permission.nameModule]}</td>
                                                    <td>
                                                        <img src={permission.readAllow === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                                            className='checkbox'
                                                            onClick={() => handleChangePermission(index, "readAllow", permission.nameModule)} />
                                                    </td>
                                                    <td>
                                                        <img src={permission.writeAllow === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                                            className='checkbox'
                                                            onClick={() => handleChangePermission(index, "writeAllow", permission.nameModule)} />
                                                    </td>
                                                    <td>
                                                        <img src={permission.updateAllow === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                                            className='checkbox'
                                                            onClick={() => handleChangePermission(index, "updateAllow", permission.nameModule)} />
                                                    </td>
                                                    <td>
                                                        <img src={permission.deleteAllow === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                                            className='checkbox'
                                                            onClick={() => handleChangePermission(index, "deleteAllow", permission.nameModule)} />
                                                    </td>
                                                </tr>
                                            })
                                            }
                                        </tbody>
                                    </table>
                                }
                            </div>
                        </div>

                    }
                </div>

                {viewRoles && <AddButton onClick={toggleShowModal} />}

                {viewPermissions && (rolePermissions.length > 0) &&
                    <Button onClick={handleSavePermissions} className='general-button save-button primary'>
                        {language.Save}
                    </Button>}
                <Footer/>
                {isLoading &&
                    <div className='spinner-container'>
                        <Spinner animation="border" variant="primary" className='spinner' />
                    </div>
                }
            </div>

            {/* Modal */}
            <Modal show={showModal} onHide={handleCancelClick} centered>
                <Modal.Header closeButton>
                    <Modal.Title>{isEditing ? language.RoleEditing : language.RoleCreation}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='modal__inputs-row'>
                        <div className='modal__input-box'>
                            <label>{language.Name}</label>
                            <div className='modal__inline-elements'>
                                <input placeholder={language.RoleName} className='module__input-search modal__name-input'
                                    onChange={evt => setRoleName(evt.target.value)} value={roleName} ></input>
                            </div>
                        </div>
                        <div className='modal__input-box'>
                            <label>{language.Active}</label>
                            <img src={isRoleActive ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                                className='checkbox'
                                onClick={() => handleCheckboxChange()} />
                        </div>
                    </div>

                    <label>{language.Description}</label> <br />
                    <input placeholder={language.RoleDescription} className='module__input-search modal__text-box'
                        onChange={evt => setRoleDescription(evt.target.value)} value={roleDescription}></input>
                    {
                        showAlert &&
                        <Alert variant="danger" onClose={() => setShowAlert(false)} dismissible>{alertMessage}</Alert>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={handleCancelClick}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' onClick={isEditing ? handleSaveEditClick : handleCreateRole}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>

            {/*Confirmation modal*/}
            <Modal centered show={viewConfirmModal} onHide={() => setViewConfirmModal(false)}>
                <Modal.Body>
                    <p>{language.ConfirmPermissionChange}</p>
                    <div className='active'>{language.Roles} - {confirmingAction.name}</div>
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={() => setViewConfirmModal(false)}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' onClick={() => confirmPermissionChange(confirmingAction.index, confirmingAction.action)}>
                        {language.Save}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default RolePage;