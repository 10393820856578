import Button from 'react-bootstrap/Button';
import { Spinner } from 'react-bootstrap';

import './css/ChangeTicketPage.css';
import getQR from '../../api/TVM/getQR';
import TicketQrModal from './TicketQrModal';

import { useEffect, useRef, useState } from 'react';
import disableQR from '../../api/TVM/disableQR';
import ErrorCases from '../../Common/ErrorCases';
import Footer from '../../Common/Footer';

function ChangeTicket() {
    const language = JSON.parse(localStorage.getItem('language'));
    const [qrCode, setQrCode] = useState('');
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const languageCode = currentUser.data.userData.languageCode;
    const [ticket, setTicket] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [showQrCode, setShowQrCode] = useState(false);
    const [qrError, setQrError] = useState('');
    const inputRef = useRef(null);

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const handleFindTicket = async () => {
        try {
            setIsLoading(true);
            setQrError('');
            const ticketData = await getQR(currentTenant, qrCode);
            if (ticketData.error) {
                const error = ErrorCases(ticketData.error);
                setQrError(error);
                return;
            };
            setTicket(ticketData);
        } catch (error) {
            console.log(error)
        } finally {
            setIsLoading(false);
        }
    };

    const handleDisableQr = async () => {
        setIsLoading(true);
        disableQR(currentTenant, false, ticket.OID)
        const ticketData = await getQR(currentTenant, qrCode);
        setTicket(ticketData);
        setIsLoading(false);
    };

    const clearTicket = () => {
        setTicket([]);
        setQrCode('');
        inputRef.current.focus();
    };

    const handleEnterKey = async (event) => {
        if (event.key === "Enter") {
            event.preventDefault(); 
            await handleFindTicket();
        }
    };

    return (
        <>
            <div className='main-container-app' >
                <h1 className='module__title'>{language.ChangeTicket}</h1>
                <div className="main-box" style={{ flexGrow: '0' }}>
                    <div className='input-box'>
                        <input placeholder={language.EnterQRCode}
                            className='ticket-input qrCode'
                            onChange={evt => setQrCode(evt.target.value)}
                            value={qrCode}
                            ref={inputRef}
                            onKeyDown={handleEnterKey}
                        ></input>
                        <div className='error-alert' style={{ width: '50%' }}>{qrError}</div>
                    </div>
                    <Button onClick={handleFindTicket} className='general-button primary'
                        style={{ fontSize: '0.8rem', marginTop: '1rem', marginRight: '0.5rem' }}>
                        {language.FindTicket}
                    </Button>
                    {Object.keys(ticket).length > 0 &&
                        <Button onClick={handleDisableQr} className='general-button primary'
                            style={{ fontSize: '0.8rem', marginTop: '1rem' }}>
                            {language.Disable}
                        </Button>
                    }
                    {Object.keys(ticket).length > 0 &&
                        <>
                            <div className='input__label'>{language.Agency}</div>
                            <input className='ticket-input data'
                                value={ticket.agencyName} disabled></input>
                            <div className='input__label'>{language.TicketNumber}</div>
                            <input className='ticket-input data'
                                value={ticket.ticketNumber} disabled></input>
                            <div className='input__label'>{language.Type}</div>
                            <input className='ticket-input data'
                                value={ticket.type} disabled></input>
                            <div className='input__label'>{language.Issued}</div>
                            <input className='ticket-input data'
                                value={new Date(ticket.Issued).toLocaleDateString(languageCode)} disabled></input>
                            <div className='input__label'>{language.Expiration}</div>
                            <input className='ticket-input data'
                                value={new Date(ticket.expiration).toLocaleDateString(languageCode)} disabled></input>
                            <div className='input__label'>{language.TransactionId}</div>
                            <input className='ticket-input data'
                                value={ticket.transactionId} disabled></input>
                            <div className='input__label'>{language.TvmId}</div>
                            <input className='ticket-input data'
                                value={ticket.tvmId} disabled></input>
                            <div className='input__label'>{language.Balance}</div>
                            <input className='ticket-input data'
                                value={ticket.balance} disabled></input>
                            <div className='input__label'>{language.TicketSource}</div>
                            <input className='ticket-input data'
                                value={ticket.source} disabled></input>
                            <div className='input__label'>{language.Status}</div>
                            <input className='ticket-input data'
                                value={ticket.Active ? language.Active : language.Disable} disabled></input>
                            <br />

                            <Button onClick={() => setShowQrCode(true)} className='general-button primary'
                                style={{ fontSize: '0.8rem', marginTop: '1rem', marginRight: '0.5rem' }}>
                                {language.QRCode}
                            </Button>
                            <Button onClick={() => clearTicket([])} className='general-button danger'
                                style={{ fontSize: '0.8rem', marginTop: '1rem' }}>
                                {language.ClearTicket}
                            </Button>
                            {ticket.Code && showQrCode &&
                                <TicketQrModal
                                    ticket={ticket}
                                    show={showQrCode}
                                    onHide={() => setShowQrCode(false)}
                                />
                            }
                        </>
                    }
                </div>
                <Footer />
                {isLoading &&
                    <div className='spinner-container'>
                        <Spinner animation="border" variant="primary" className='spinner' />
                    </div>
                }

            </div>

        </>
    )
}

export default ChangeTicket;