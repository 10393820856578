import GetMethod from "../../Hooks/API/GetMethod";

const GetAssignedTenants = async (tenantsDB, idCognito) =>{

    const path = `/Tenants?tenantsDB=${tenantsDB}&isAssigned=true&idCognito=${idCognito}`;
    try {
        const { data, error } = await GetMethod(path);
        return { data, error };
    } catch (error) {
        return {error};
    }
}

export default GetAssignedTenants;