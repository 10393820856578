import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useNavigate } from 'react-router-dom';
import { getCurrentUser } from 'aws-amplify/auth';

import { Amplify } from 'aws-amplify';
import amplifyConfig from './amplifyconfiguration.json';

import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@aws-amplify/ui-react/styles.css';
import './index.css';

import NavBar from './components/Common/NavBar';
import RegisterUserPage from './components/page/RegisterUserPage';
import UserManagerPage from './components/page/UserManager/UserManagerPage';
import RolePage from './components/page/UserManager/RolesPage';
import PrivateRoute from './components/auth/PrivateRoute';

import LoginPage from './components/auth/LoginPage';
import SmsCode from './components/auth/SmsCode';
import ForgotPassword from './components/auth/ForgotPassword';
import ResetPassword from './components/auth/ResetPassword';
import NewPassword from './components/auth/NewPassword';
import EmailCode from './components/auth/EmailCode';
import CreateUser from './components/page/UserManager/CreateUser';
import Countries from './components/page/Location/CountriesPage';
import States from './components/page/Location/StatesPage';
import Cities from './components/page/Location/CitiesPage';
import Stations from './components/page/Location/StationsPage';
import Products from './components/page/Fares/ProductsPage';
import GeneralDashboard from './components/page/DashBoard/GeneralDashboard';
import Currency from './components/page/Fares/CurrencyPage';
import DashboardAlarm from './components/page/DashBoard/DashboardAlarm';
import TVM from './components/page/DeviceManager/TVM/TVMPage';
import GlobalConfig from './components/page/DeviceManager/TVM/GlobalConfigPage';
import CustomAlarmsPage from './components/page/DeviceManager/TVM/CustomAlarmsPage';
import Devices from './components/page/DeviceManager/Configuration/DevicesPage';
import Emails from './components/page/DeviceManager/Configuration/EmailsPage';
import Commands from './components/page/DeviceManager/Configuration/CommandsPage';
import SelectTenant from './components/auth/SelectTenant';
import DashboardTransactions from './components/page/DashBoard/DashboardTransactions';
import TransactionsPage from './components/page/Reports/TransactionsPage';
import AlarmsPage from './components/page/Reports/AlarmsPage';
import ChangesPage from './components/page/Reports/ChangesPages';
import CustomReportsPage from './components/page/Reports/CustomReportsPage';
import ChangeTicket from './components/page/CustomerService/ChangeTicketPage';
import FullStatusControl from './components/page/DeviceManager/TVM/FullStatusControlPage';
import Tenants from './components/page/UserManager/TenantsPage';
import AccessDenied from './components/Common/AccessDeniedPage';
import CompareDashboard from './components/page/DashBoard/CompareDashboard';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader, ModalTitle } from 'react-bootstrap';
import { signOut } from 'aws-amplify/auth';
import LanguagePage from './components/page/DeviceManager/Configuration/LanguagePage';
import TenantsAssignment from './components/page/UserManager/TenantsAssignment';
import LanguageTVMPage from './components/page/DeviceManager/Configuration/LanguageTVMPage';

Amplify.configure(amplifyConfig);

function App() {
  const [authenticated, setAuthenticated] = useState(false);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const [showSubn, setShowSubn] = useState(false);

  useEffect(() => {
    checkAuthentication();


  }, []);

  const checkAuthentication = async () => {
    try {
      const currentUser = await getCurrentUser();
      setAuthenticated(!!currentUser);
    } catch (error) {
      setAuthenticated(false);
    }
  };

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  return (
    <div>
      <NavBar showSubn={showSubn} setShowSubn={setShowSubn} />
      <div >
        <Routes >
          <Route path='/login' element={<LoginPage />} />
          <Route path='/sms-code' element={<SmsCode />} />
          <Route path='/forgot-password' element={<ForgotPassword />} />
          <Route path='/reset-password' element={<ResetPassword />} />
          <Route path='/new-password' element={<NewPassword />} />
          <Route path='/email-code' element={<EmailCode />} />
          <Route path='/select-tenant' element={<SelectTenant />} />
          <Route path='/access-denied' element={<AccessDenied />} />
          <Route path='/' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="GeneralDashboard" />} >
            <Route path='*' element={<GeneralDashboard />} />
            <Route path='/' element={<GeneralDashboard />} />
            <Route path='/Home' element={<GeneralDashboard />} />
          </Route>
          <Route path='/dashboard-alarms' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="TVMDashboardAlarms" />} >
            <Route path='/dashboard-alarms' element={<DashboardAlarm />} />
          </Route>
          <Route path='/dashboard-transactions' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="TVMDashboardTransactions" />} >
            <Route path='/dashboard-transactions' element={<DashboardTransactions />} />
          </Route>
          <Route path='/user-manager' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="UMUsers" />} >
            <Route path='/user-manager' element={<UserManagerPage />} />
          </Route>
          <Route path='/roles' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="UMRoles" />} >
            <Route path='/roles' element={<RolePage />} />
          </Route>
          <Route path='/location-countries' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="LocationCountries" />} >
            <Route path='/location-countries' element={<Countries />} />
          </Route>
          <Route path='/location-states' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="LocationStates" />} >
            <Route path='/location-states' element={<States />} />
          </Route>
          <Route path='/location-cities' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="LocationCities" />} >
            <Route path='/location-cities' element={<Cities />} />
          </Route>
          <Route path='/location-stations' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="LocationStation" />} >
            <Route path='/location-stations' element={<Stations />} />
          </Route>
          <Route path='/fare-products' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="FareProducts" />} >
            <Route path='/fare-products' element={<Products />} />
          </Route>
          <Route path='/fare-currency' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="FareCurrency" />} >
            <Route path='/fare-currency' element={<Currency />} />
          </Route>
          <Route path='/dmtvm' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="DMTVM" />} >
            <Route path='/dmtvm' element={<TVM />} />
          </Route>
          <Route path='/dmtvm-global-config' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="GLobalConfigTVM" />} >
            <Route path='/dmtvm-global-config' element={<GlobalConfig />} />
          </Route>
          <Route path='/dmtvm-custom-alarms' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="DMTVMCustomAlarms" />} >
            <Route path='/dmtvm-custom-alarms' element={<CustomAlarmsPage />} />
          </Route>
          <Route path='/dm-config-devices-alarms' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="DMConfigDevicesAlarms" />} >
            <Route path='/dm-config-devices-alarms' element={<Devices />} />
          </Route>
          <Route path='/dm-config-email-alarms' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="DMConfigEmailAlarms" />} >
            <Route path='/dm-config-email-alarms' element={<Emails />} />
          </Route>
          <Route path='/dm-config-commands' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="DMConfigCommands" />} >
            <Route path='/dm-config-commands' element={<Commands />} />
          </Route>
          <Route path='/reports-transactions' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="ReportsTransactions" />} >
            <Route path='/reports-transactions' element={<TransactionsPage />} />
          </Route>
          <Route path='/reports-alarms' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="ReportsAlarms" />} >
            <Route path='/reports-alarms' element={<AlarmsPage />} />
          </Route>
          <Route path='/reports-custom' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="ReportsCustom" />} >
            <Route path='/reports-custom' element={<CustomReportsPage />} />
          </Route>
          <Route path='/reports-changes' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="ReportsChanges" />} >
            <Route path='/reports-changes' element={<ChangesPage />} />
          </Route>
          <Route path='/change-ticket' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="CSChangeQR" />} >
            <Route path='/change-ticket' element={<ChangeTicket />} />
          </Route>
          <Route path='/full-status-control' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="TVMFullStatusControl" />} >
            <Route path='/full-status-control' element={<FullStatusControl />} />
          </Route>
          <Route path='/tenants' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="UMTenants" />} >
            <Route path='/tenants' element={<Tenants />} />
          </Route>
          <Route path='/dashboard-compare' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="TVMDashboardCompare" />} >
            <Route path='/dashboard-compare' element={<CompareDashboard />} />
          </Route>
          <Route path='/dm-config-language' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="LanguagesFileEMS" />} >
            <Route path='/dm-config-language' element={<LanguagePage />} />
          </Route>
          <Route path='/dm-config-language-tvm' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="LanguagesFileTVM" />} >
            <Route path='/dm-config-language-tvm' element={<LanguageTVMPage />} />
          </Route>
          <Route path='/tenants-assignment' element={<PrivateRoute className={`app-container ${showSubn ? 'navbar-open' : 'navbar-closed'}`} moduleName="UMTenants" />} >
            <Route path='/tenants-assignment' element={<TenantsAssignment />} />
          </Route>
        </Routes>

        
      </div>
      
    </div>
  );
}

export default App;
