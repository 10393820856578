import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchAuthSession } from 'aws-amplify/auth';
import 'bootstrap/dist/css/bootstrap.min.css';
import getAllUsers from '../../api/EMSuser/getAllUsers';
import editUser from '../../api/EMSuser/editUser';
import resetPassword from '../../api/EMSuser/resetPassword';
import DisableUser from '../../auth/DisableUser';
import EnableUser from '../../auth/EnableUser';
import UsersOverlay from './UsersOverlay';
import UsersModal from './UsersModal';
import deleteUser from '../../api/EMSuser/deleteUser';
import getPermission from '../../auth/GetPermission';
import GeneralTable from '../../Hooks/Table/GeneralTable';
import CreateUserModal from './CreateUser';
import DeleteModal from '../../Common/DeleteModal';

import { Box } from '@mui/material';
import AddButton from '../../Common/AddButton';

import Alert from 'react-bootstrap/Alert';
import { Button, Modal, Spinner } from 'react-bootstrap';
import ErrorCases from '../../Common/ErrorCases';
import GetUser from '../../api/EMSuser/getUser';
import Footer from '../../Common/Footer';

function UserManager() {
    const [data, setData] = useState([]);
    const [error, setError] = useState(null);
    const [editingUserId, setEditingUserId] = useState(null);
    const [editedUserData, setEditedUserData] = useState([]);
    const [isEditing, setIsEditing] = useState(false);
    const [userNameFilter, setUserNameFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [elementsPerPage] = useState(100);
    const navigate = useNavigate();
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [editingUser, setEditingUser] = useState({});
    let userToEdit = {};
    const [showModal, setShowModal] = useState(false);
    const [showOverlay, setShowOverlay] = useState(false);
    const [alertVariant, setAlertVariant] = useState("");
    const [alertMessage, setAlertMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [isChangeEmail, setIsChangeEmail] = useState(false);
    const [isChangePhone, setIsChangePhone] = useState(false);
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    const currentTenant = currentUser.data.currentTenant;
    const language = JSON.parse(localStorage.getItem('language'));
    const [showCreateUserModal, setShowCreateUserModal] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [deleteError, setDeleteError] = useState('');
    const [showDisableModal, setShowDisableModal] = useState(false);
    const [disablingUser, setDisablingUser] = useState([]);

    const handleClick = (event, userId) => {
        const { clientX, clientY } = event;
        setPosition({ x: clientX - 150, y: clientY + 50 });
        setShowOverlay(showOverlay ? false : true);
        userToEdit = data.find((user) => user.id === userId);
        setEditingUser(userToEdit);
    };

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                const response = await getData();

                setData(response);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
        fetchData();
    }, [currentPage]);

    async function getData() {
        const { data, error } = await getAllUsers(currentTenant);

        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    }

    const handleEditClick = (userId) => {
        userToEdit = data.find((user) => user.id === userId);

        setEditedUserData(userToEdit);
        setIsEditing(true);
        setShowCreateUserModal(true);
    };

    const handleEnableUser = async (userName, id, dataUser) => {
        await EnableUser(userName);
        dataUser.isActive = 1;
        await editUser(dataUser, id);
        const response = await getData();
        setData(response);
    };

    const handleDisableUser = async (userName, id, dataUser) => {
        try {
            setIsLoading(true);
            await DisableUser(userName);
            dataUser.isActive = 0;
            await editUser(dataUser, id);
            const response = await getData();
            setData(response);
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
            setShowDisableModal(false);
        }
    };

    const handleActiveChange = async (isActive, username, id) => {
        setIsLoading(true);
        try {
            const response = await GetUser(currentTenant, id);
            const user = response.data.userData;
            const dataUser = {
                name: user.name,
                email: user.email,
                phone: user.phone,
                languageId: user.languageId,
                notifications: user.notifications,
                roleId: user.roleId,
                isActive: user.isActive,
                tenantsDB: currentTenant
            }
            if (isActive === 1) {
                setDisablingUser({ username, id, dataUser });
                setShowDisableModal(true);
            } else {
                await handleEnableUser(username, id, dataUser);
            }
        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    async function handleResetPassword(username) {
        try {
            const userData = {
                username: username,
                tenantsDB: currentTenant
            }
            const output = await resetPassword(userData);
            if (output.error !== "" && output.error.includes("Resend not possible")) {
                setAlertMessage("The password can't be resend to user: " + username + " because the email has been confirmed already");
                setAlertVariant('danger');
                setShowAlert(true);
            }
            if (output.error !== "" && output.error.includes("User does not exist")) {
                setAlertMessage("The user: " + username + " doesn't exist");
                setAlertVariant('danger');
                setShowAlert(true);
            }
            if (output.data.responseCognito.User.UserStatus && output.data.responseCognito.User.UserStatus === 'FORCE_CHANGE_PASSWORD') {
                setAlertMessage('The password has been resend to user: ' + username);
                setAlertVariant('primary');
                setShowAlert(true);
            }
        } catch (error) {
            //console.log({error});
        }
    }

    function handleChangeEmail() {
        setIsChangeEmail(true);
        setIsChangePhone(false);
        setShowModal(true);
    }

    const handleChangePhone = () => {
        setIsChangePhone(true);
        setIsChangeEmail(false);
        setShowModal(true);
    }

    async function handleDeleteClick(id) {
        try {
            setIsLoading(true);
            const tenant = currentTenant;
            const response = await deleteUser(id, tenant);
            if (response.error) {
                const error = ErrorCases(response.error);
                setDeleteError(error);
                return;
            }
            navigate(0);

        } catch (error) {
            console.error(error);
        } finally {
            setIsLoading(false);
        }
    };

    const columns = useMemo(
        () => [
            {
                header: language.Name,
                accessorKey: 'name',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
            },
            {
                header: language.Email,
                accessorKey: 'email',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'left',
                },
                Cell: ({ renderedCellValue, row }) => (
                    <Box>
                        {row.original.name === currentUser.data.userData.name ? renderedCellValue :
                            renderedCellValue.slice(0, 3) + "***" + renderedCellValue.slice(renderedCellValue.indexOf("@"))
                        }
                    </Box>
                ),
            },
            {
                header: language.Phone,
                accessorKey: 'phone',
                muiTableHeadCellProps: {
                    align: 'left',
                },
                muiTableBodyCellProps: {
                    align: 'right',
                },
                Cell: ({ renderedCellValue, row }) => (
                    <Box>
                        {row.original.name === currentUser.data.userData.name ? renderedCellValue :
                            "***" + renderedCellValue.substring(renderedCellValue.length - 4)
                        }
                    </Box>
                ),

            },
            {
                header: language.Active,
                accessorKey: 'isActive',
                Cell: ({ renderedCellValue, row }) => (
                    <Box>
                        <img src={renderedCellValue === 1 ? './img/icons/pages/CheckMark.svg' : './img/icons/pages/CroseMark.svg'}
                            style={{ cursor: 'pointer', width: '25px' }}
                            onClick={() => handleActiveChange(row.original.isActive, row.original.name, row.original.id)} />
                    </Box>
                ),
            },
        ],
        [],
    );

    const rowActions = ({ row }) => (
        <Box >
            <button
                className="btn"
                onClick={() => handleEditClick(row.original.id)}
                disabled={(!getPermission(currentUser, "UMUsers", "updateAllow"))}>
                <img src='./img/icons/pages/EditUser-01.png' className='table__icon' />
            </button>
            <button
                className="btn"
                onClick={(event) => handleClick(event, row.original.id)}
                disabled={(row.original.name !== currentUser.data.userData.name) && (!getPermission(currentUser, "UMUsers", "updateAllow"))}>
                <img src='./img/icons/pages/Points-01.png' className='table__icon' />
            </button>
        </Box>
    );

    const deleteMode = () => {
        setDeleteError('');
        setShowDeleteModal(true);
    };

    const handleCreateUser = () => {
        setIsEditing(false);
        setShowCreateUserModal(true);
    };

    return (
        <div className='main-container-app'>

            <h1 className='module__title'>{language.UserManagement}</h1>

            {/*datatable component*/}
            <GeneralTable data={data} columns_table={columns} actions={true} rowActions={rowActions} />

            <AddButton onClick={handleCreateUser} />

            {/* More actions card */}
            {showOverlay &&
                <UsersOverlay show={true}
                    onClose={() => setShowOverlay(false)}
                    position={position}
                    setShowModal={setShowModal}
                    handleResetPassword={() => handleResetPassword(editingUser.name)}
                    handleChangeEmail={() => handleChangeEmail()}
                    handleChangePhone={() => handleChangePhone()}
                    username={editingUser.name}
                    handleDeleteClick={deleteMode}
                    editingUser={editingUser}
                />
            }

            {/* Actions modal */}
            <UsersModal show={showModal}
                onHide={() => setShowModal(false)}
                isChangeEmail={isChangeEmail}
                username={editingUser.name}
                isChangePhone={isChangePhone}
            />

            <CreateUserModal
                show={showCreateUserModal}
                onHide={() => setShowCreateUserModal(false)}
                editedUserData={editedUserData}
                editing={isEditing}
            />
            {/* Alerts */}
            {
                showAlert &&
                <Alert variant={alertVariant} onClose={() => setShowAlert(false)} dismissible>
                    {alertMessage}
                </Alert>
            }
            {
                showDeleteModal &&
                <DeleteModal
                    show={showDeleteModal}
                    onHide={() => setShowDeleteModal(false)}
                    message={language.DeleteUserMessage + editingUser.name}
                    handleDelete={() => handleDeleteClick(editingUser.id)}
                    error={deleteError}
                />
            }
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
            <Modal show={showDisableModal} onHide={() => setShowDisableModal(false)} className='modal' centered>
                <Modal.Body>
                    {language.DisableAlert}
                </Modal.Body>
                <Modal.Footer>
                    <Button className='general-button danger' onClick={() => setShowDisableModal(false)}>
                        {language.Cancel}
                    </Button>
                    <Button className='general-button primary' onClick={() => handleDisableUser(disablingUser.username, disablingUser.id, disablingUser.dataUser)}>
                        {language.Confirm}
                    </Button>
                </Modal.Footer>
            </Modal>
            <Footer />
        </div>
    );
}

export default UserManager;