import { Button, Card, ListGroup, Spinner } from "react-bootstrap";
import Footer from "../../Common/Footer";
import { useEffect, useState } from "react";
import GetAllUsers from "../../api/EMSuser/getAllUsers";
import GetAllTenants from "../../api/Tenants/getAllTenants";
import AssignTenant from "../../api/Tenants/assignTenant";
import { useLocation, useNavigate } from 'react-router-dom';
import GetAssignedTenants from "../../api/Tenants/getAssignedTenants";
import GetPermission from "../../auth/GetPermission";
import GetUser from "../../api/EMSuser/getUser";

function TenantsAssignment() {
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [currentUser, setCurrentUser] = useState(JSON.parse(localStorage.getItem('currentUser')));
    const [currentTenant, setCurrentTenant] = useState(currentUser.data.currentTenant);
    const [error, setError] = useState(null);
    const language = JSON.parse(localStorage.getItem('language'));
    const [selectedTenants, setSelectedTenants] = useState([]);
    const location = useLocation();
    const { editingUser } = location.state || {};
    const [alertMessage, setAlertMessage] = useState('');
    const [currentTenantName, setCurrentTenantName] = useState('');
    const [singleTenant, setSingleTenant] = useState('');
    const [originalSelection, setOriginalSelection] = useState([]);
    const [canAssign, setCanAssign] = useState(false);
    const [updateAssigned, setUpdateAssigned] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchData() {
            try {
                setIsLoading(true);
                setSelectedTenants([]);
                const tenantsResponse = await getTenantsData();
                setData(tenantsResponse.tenants);
                setCurrentTenantName(tenantsResponse.tenants.find(item => item.tenantsDBName === currentTenant).tenantsName);
                const response = await getData();
                setSelectedTenants(response.tenants);
                setSingleTenant(getSingleAssignedTenantName(selectedTenants));
                setOriginalSelection(response.tenants);
            } catch (error) {
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
        editingUser === undefined && navigate('/user-manager');
        fetchData();
    }, [currentTenant, updateAssigned]);

    async function getData() {
        const { data, error } = await GetAssignedTenants(currentTenant, editingUser.idCognito);
        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    async function getTenantsData() {
        const { data, error } = await GetAllTenants(currentTenant);
        if (error !== undefined) {
            setError(error);
            return [];
        } else {
            return data;
        }
    };

    const handleAssignTenants = async () => {
        const changedTenants = getChangedTenants(originalSelection, selectedTenants);
        const tenantsAssignmentList = changedTenants.map(tenant => ({
            idCognito: editingUser.idCognito,
            idTenants: data.find(item => item.tenantsName === tenant.tenantsName).idTenants,
            isAssigned: tenant.assignmentStatus === 1
        })
        );
        const body = {
            tenantsDB: currentTenant,
            tenantAssignmentList: tenantsAssignmentList
        };
        try {
            setIsLoading(true);
            const response = await AssignTenant(body);
            if (response.error && response.error.includes('Cannot delete or update a parent row: a foreign key constraint fails')) {
                setAlertMessage(language.CantUnassignedTenant);
                setTimeout(() => {
                    setAlertMessage('');
                }, 5000);
            }
            setUpdateAssigned(!updateAssigned);
        } catch (error) {
            console.error(error);
            setAlertMessage(language.SomethingWentWrong);
            setTimeout(() => {
                setAlertMessage('');
            }, 5000);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCheckboxClick = (index) => {
        setAlertMessage('');
        const isCurrentlyAssigned = selectedTenants[index].assignmentStatus === 1;
        if (isCurrentlyAssigned && selectedTenants.filter(t => t.assignmentStatus === 1).length === 1) {
            setAlertMessage(language.OneTenant)
            return;
        }
        const updatedTenants = selectedTenants.map((tenant, i) => {
            if (i === index) {
                return { ...tenant, assignmentStatus: tenant.assignmentStatus === 1 ? 0 : 1 };
            }
            return tenant;
        });
        setSelectedTenants(updatedTenants);
    };

    const getSingleAssignedTenantName = (tenants) => {
        const assignedTenants = tenants.filter(tenant => tenant.assignmentStatus === 1);
        if (assignedTenants.length === 1) {
            return assignedTenants[0].tenantsName;
        }
        return null;
    };

    const getPermission = () => {
        const modulePermission = GetPermission(currentUser, "UMTenants", "updateAllow");
        const hasChanged = hasSelectionChanged(originalSelection, selectedTenants);
        return modulePermission && hasChanged;
    };

    const hasSelectionChanged = (originalSelection, selectedTenants) => {
        for (let i = 0; i < originalSelection.length; i++) {
            const originalTenant = originalSelection[i];
            const selectedTenant = selectedTenants.find(
                (tenant) => tenant.tenantsName === originalTenant.tenantsName
            );
            if (!selectedTenant || originalTenant.assignmentStatus !== selectedTenant.assignmentStatus) {
                return true;
            }
        }
        return false;
    };

    useEffect(() => {
        const permission = getPermission();
        setCanAssign(permission);
    }, [selectedTenants]);

    const getChangedTenants = (originalSelection, selectedTenants) => {
        return selectedTenants.filter((tenant, index) =>
            tenant.assignmentStatus !== originalSelection[index]?.assignmentStatus
        );
    };

    return (
        <div className="main-container-app">
            <h1 className='module__title'>{language.TenantsAssignment}</h1>
            <div className="main-box" >
                <div className='input__label'>{language.CurrentTenant}: <span style={{ fontFamily: 'Open Sans Bold' }}>{currentTenantName}</span></div>
                <div className='inputs-row__no-justified email-assign__row'>
                    <div style={{ width: '45%' }}>
                        <Card style={{ height: '14rem', overflow: 'auto' }}>
                            <Card.Header className='card__label'>{language.Tenants}</Card.Header>
                            <ListGroup variant="flush">
                                {selectedTenants.map((tenant, index) => {
                                    return (
                                        <ListGroup.Item className='card__item'><input type="checkbox"
                                            value={tenant.idTenants}
                                            key={tenant.idTenants}
                                            onChange={() => handleCheckboxClick(index)}
                                            checked={tenant.assignmentStatus === 1}
                                            className='email-assign__checkbox'
                                            disabled={tenant.tenantsName === currentTenantName && tenant.assignmentStatus === 1 || tenant.tenantsName === singleTenant}
                                        />
                                            {tenant.tenantsName}</ListGroup.Item>
                                    )
                                })}
                            </ListGroup>
                        </Card>
                        <div className="alert-message">{alertMessage}</div>
                    </div>
                </div>
                <button onClick={handleAssignTenants}
                    className={`general-button ${canAssign ? 'primary' : 'disabled'}`}
                    style={{ marginTop: '1rem' }}
                    disabled={!canAssign}
                >{language.Assign}</button>
            </div>
            {isLoading &&
                <div className='spinner-container'>
                    <Spinner animation="border" variant="primary" className='spinner' />
                </div>
            }
            <Footer />
        </div>
    )
}

export default TenantsAssignment;