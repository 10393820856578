import React from 'react';
import './css/UsersOverlay.css';
import getPermission from '../../auth/GetPermission';
import { useNavigate } from 'react-router-dom';

function UsersOverlay({ show, onClose, position, setShowModal, handleResetPassword, handleChangeEmail, handleChangePhone, username, handleDeleteClick, editingUser }) {
  const currentUser = JSON.parse(localStorage.getItem('currentUser'));
  const tenantsOrigin = currentUser.data.userData.tenantsOrigin;
  const language = JSON.parse(localStorage.getItem('language'));
  const navigate = useNavigate();

  return (
    <>
      {show && (<>

        <div className="overlay" onClick={onClose} style={{
          position: 'absolute',
          left: position.x,
          top: position.y
        }}>
          <button className="close-button" onClick={onClose}>X</button>

          {(username === currentUser.data.userData.name || getPermission(currentUser, "UMUsers", "updateAllow")) &&
            <div className='overlay__link separated' onClick={handleResetPassword}>{language.ResetPassword}</div>
          }

          {username === currentUser.data.userData.name && tenantsOrigin === 1 &&
            <div className='overlay__link separated' onClick={handleChangeEmail}>{language.ChangeEmail}</div>
          }
          {username === currentUser.data.userData.name && tenantsOrigin === 1 &&
            <div className='overlay__link separated' onClick={handleChangePhone}>{language.ChangePhone}</div>
          }
          {(getPermission(currentUser, "UMTenants", "readAllow")) &&
            <div className='overlay__link separated' onClick={() => navigate('/tenants-assignment', { state: { editingUser } })}>{language.TenantsAssignment}</div>
          }
          {(username !== currentUser.data.userData.name && getPermission(currentUser, "UMUsers", "updateAllow")) &&
            <div className='overlay__link-red' onClick={handleDeleteClick}>{language.DeleteUser}</div>
          }
          
        </div>
      </>)}
    </>
  );
}

export default UsersOverlay;
