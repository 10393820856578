import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import './css/DeleteModal.css';
import { useState } from 'react';

function DeleteModal({ message, handleDelete, onHide, show, error, tenantName, module }) {
    const language = JSON.parse(localStorage.getItem('language'));
    const [confirmation, setConfirmation] = useState();
    const [validateError, setValidateError] = useState("");

    const validateConfirmation = () => {
        const normalizedConfirmation = confirmation.trim().toLowerCase();
        const normalizedTenantName = tenantName.trim().toLowerCase();

        if (normalizedConfirmation === normalizedTenantName) {
            handleDelete();
        } else {
            setValidateError(language.ValidateTenantError);
            setTimeout(() => {
                setValidateError("");
            }, 5000);
        }
    };

    return (
        <Modal show={show} onHide={onHide} centered>
            <Modal.Body>
                <div className='delete-tenant-message'>{message}
                    {module === 'tenants' &&
                        <span style={{ fontFamily: 'Open Sans Bold' }}>"{tenantName}"</span>
                    }
                </div>
                {module === 'tenants' &&
                    <input onChange={event => setConfirmation(event.target.value)} className='module__input-search' style={{ width: '50%' }} />
                }

                <div className='error-alert' style={{ textAlign: 'left' }}>{error}</div>
                <div className='error-alert' style={{ textAlign: 'left' }}>{validateError}</div>
            </Modal.Body>
            <Modal.Footer>
                <Button className="general-button primary" onClick={onHide}>
                    {language.Cancel}
                </Button>
                <Button className="general-button danger" onClick={module === 'tenants' ? validateConfirmation : handleDelete}>
                    {language.Delete}
                </Button>
            </Modal.Footer>
        </Modal>
    );

}

export default DeleteModal; 